<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/domainMarkList' }">網域標記列表</el-breadcrumb-item>
                <el-breadcrumb-item>新增網域標記</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="form-box">
                <div class="form-title">新增網域標記</div>
                <FormTemplate :formParams="form"
                              :deafulcomForm="deafuleditForm"
                              @reset-form="resetForm"
                              :btnText="'重填'"
                              @on-submit="onSubmit">
                </FormTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import FormTemplate from '../../../components/FormTemplate.vue'
import { domainMarkCreate } from '../../../api/index'
export default {
    components: {
        FormTemplate,
    },
    data() {
        return {
            form: {
                labelWidth: '150px',
                formList: [
                    {
                        label: '域名',
                        key: 'domain',
                        type: 'INPUT',
                        placeholder: '請輸入域名',
                        require: true,
                        span: 24,
                    },
                    {
                        label: '是否為汙染',
                        key: 'is_corrupted',
                        type: 'RADIO',
                        lists: [
                            {
                                name: '是',
                                label: 1,
                            },
                            {
                                name: '否',
                                label: 0,
                            },
                        ],
                    },
                    {
                        label: '是否為昂貴域名',
                        key: 'is_expensive',
                        type: 'RADIO',
                        lists: [
                            {
                                name: '是',
                                label: 1,
                            },
                            {
                                name: '否',
                                label: 0,
                            },
                        ],
                    },
                    {
                        label: '備註',
                        key: 'note',
                        type: 'INPUT',
                        placeholder: '請輸入備註',
                        span: 24,
                    },
                ],
            },
            deafuleditForm: {},
        }
    },
    mounted() {},
    methods: {
        onSubmit(form) {
            domainMarkCreate(form)
                .then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.$router.push({
                            path: 'domainMarkList',
                        })
                    } else if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error('失敗')
                })
        },
        resetForm() {
            this.deafuleditForm = {}
        },
    },
}
</script>

<style lang="scss">
.go-back-font {
    .el-breadcrumb__inner {
        font-weight: 700;
        color: #303133;
    }
    .el-breadcrumb__inner:hover {
        color: #409eff;
        cursor: pointer;
    }
}
</style>
<style lang="scss" scoped>
</style>