<template>
    <div>
        <el-form ref="comForm"
                 :model="comForm"
                 :rules="ruleValidate"
                 :label-width="labelWidth"
                 label-position="left"
                 status-icon>
            <template v-for="(formItem, index) in formList"
                      :key="index">
                <el-form-item :label="formItem.label"
                              :prop="formItem.key"
                              v-if="formItem.vif? filter(formItem) : true ">
                    <el-row v-if="formItem.isRow"
                            :gutter="20">
                        <el-col v-for="(item, index) in formItem.lists"
                                :key="index"
                                :span="item.span">
                            <el-input v-if="item.type === 'INPUT'"
                                      v-model="comForm[item.key]"
                                      :placeholder="item.placeholder"></el-input>
                            <el-select v-else-if="item.type === 'SELECT'"
                                       v-model="comForm[item.key]"
                                       :placeholder="item.placeholder">
                                <el-option v-for="item in item.lists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>

                            </el-select>
                        </el-col>

                    </el-row>
                    <el-row v-else>
                        <el-col :span="formItem.span"
                                v-if="formItem.type === 'TEXT'">
                            <div>{{comForm[formItem.key]}}</div>
                        </el-col>
                        <el-col :span="formItem.span"
                                v-if="formItem.type === 'INPUT'">
                            <el-input v-model="comForm[formItem.key]"
                                      :placeholder="formItem.placeholder"></el-input>
                            <div v-if="formItem.tip"
                                 class="el-upload__tip">
                                {{formItem.tip}}
                            </div>
                        </el-col>
                        <el-col :span="formItem.span"
                                v-if="formItem.type === 'SELECT'">
                            <el-select v-if="formItem.array"
                                       v-model="comForm[formItem.key]"
                                       multiple
                                       collapse-tags
                                       class="select-type"
                                       :placeholder="formItem.placeholder">
                                <el-option v-for="item in formItem.lists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>

                            </el-select>
                            <el-select v-else
                                       v-model="comForm[formItem.key]"
                                       @change="selectChange"
                                       class="select-type"
                                       :placeholder="formItem.placeholder">
                                <el-option v-for="item in formItem.lists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>

                            </el-select>
                        </el-col>
                        <el-col :span="formItem.span"
                                v-if="formItem.type === 'CKECKBOX'">
                            <el-checkbox-group v-model="comForm[formItem.key]">
                                <el-checkbox v-for="item in formItem.lists"
                                             :label="item.label"
                                             :checked="checked"
                                             @change="checked=!checked"
                                             :key="item.key"></el-checkbox>

                            </el-checkbox-group>
                        </el-col>
                        <el-col :span="formItem.span"
                                v-if="formItem.type === 'RADIO'">
                            <el-radio-group v-model="comForm[formItem.key]"
                                            v-for="(item,index) in formItem.lists"
                                            :key="index">
                                <el-radio :label="item.label">{{item.name}}</el-radio>
                            </el-radio-group>
                        </el-col>
                        <el-col :span="formItem.span"
                                v-if="formItem.type === 'SLOT'">
                            <slot :name="formItem.key"
                                  :comForm="comForm"
                                  :formItem="formItem"></slot>
                        </el-col>

                    </el-row>

                </el-form-item>
            </template>
            <div class="submit-btn">

                <el-button type="primary"
                           :loading="loading"
                           @click="onSubmit('comForm')">{{btnText2}}</el-button>
                <el-button @click="resetForm">{{btnText}}</el-button>

            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        formParams: Object,
        deafulcomForm: {
            type: Object,
            default() {
                return {}
            },
        },
        btnText: {
            type: String,
            default: '取消',
        },
        btnText2: {
            type: String,
            default: '確定',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formList: [],
            labelWidth: '',
            ruleValidate: '',
            comForm: {},
            checked: false,
        }
    },
    watch: {
        deafulcomForm() {
            this.comForm = this.deafulcomForm
        },
    },
    created() {
        let { formList, labelWidth } = this.formParams
        this.comForm = this.deafulcomForm
        this.formList = formList
        this.labelWidth = labelWidth
        this.ruleValidate = this.rules(this.formList)
    },
    methods: {
        rules(params) {
            let ruleValidate = {}
            params.forEach((val) => {
                let { require, type, key, label, specialRequire } = val
                if (require) {
                    ruleValidate[key] = []
                    let obj = {
                        required: true,
                        message: `請${type === 'INPUT' ? '輸入' : '選擇'}${label}`,
                        trigger: type === 'INPUT' ? 'blur' : 'change',
                    }
                    if (specialRequire) {
                        obj.message = specialRequire.label
                        obj.trigger = specialRequire.type
                    }
                    ruleValidate[key].push(obj)
                }
            })
            return ruleValidate
        },
        resetForm() {
            this.$emit('reset-form', this.comForm)
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('on-submit', this.comForm)
                } else {
                    this.$message.error('錯誤')
                    return false
                }
            })
        },
        //為了多選要先塞一個  var arr = [] 到 object
        filter(formItem) {
            let { vif } = formItem
            var arraynew = []
            let { comForm } = this
            comForm[vif] = arraynew
            return true
        },
        //新增其他
        addDomain() {
            if (this.comForm.other_contact.length < 5) {
                this.comForm.other_contact.push({ type: 'What’s app', contact: '' })
            }
        },
        //刪除其他
        removeDomain(index) {
            if (index !== -1) {
                this.comForm.other_contact.splice(index, 1)
            }
        },
        selectChange() {
            this.$forceUpdate()
        },
    },
}
</script>

<style>
.submit-btn {
    justify-content: center;
    display: flex;
    align-items: center;
}
.select-type {
    width: 100%;
}
.el-radio-group {
    padding-left: 10px;
}
</style>
